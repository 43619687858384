<h1 mat-dialog-title>
    <!-- <mat-icon class="{{data?.iconClass}}">{{data?.icon}}</mat-icon> -->
    {{data?.title}}
</h1>
<div mat-dialog-content>
    {{data?.message}}
</div>
<div mat-dialog-actions>
  <button mat-button class="app-text-upper {{data?.buttonClass ?? 'app-btn-primary'}}" (click)="onClose()" cdkFocusInitial *ngIf="data?.buttonText && data?.buttonText != ''">{{data?.buttonText}}</button>
</div>
