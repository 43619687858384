import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { IndexedDbService } from './indexed-db.service';

export enum LogLevel {
  DEBUG = 'debug',
  WARN = 'warn',
  ERROR = 'error',
  NONE = 'none'
}

type LoggingPreferences = {
  [componentName: string]: LogLevel;
};

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  private componentLogLevels = new Map<string, LogLevel>();

  private logLevel: LogLevel;

  constructor(private indexedDbService: IndexedDbService) {
    this.logLevel = this.getLogLevelFromString(environment.logLevel);
    this.initializeComponentLogLevels();
  }

  private getLogLevelFromString(levelString: string): LogLevel {
    return LogLevel[levelString.toUpperCase() as keyof typeof LogLevel] || LogLevel.NONE;
  }

  async initializeComponentLogLevels() {
    try {
      const savedLevels: LoggingPreferences | null = await this.indexedDbService.get('loggingPreferences');
      this.setLogLevelsFromSavedPreferences(savedLevels);
    } catch (error) {
      const savedLevelsString = localStorage.getItem('loggingPreferences');
      const savedLevels: LoggingPreferences = savedLevelsString ? JSON.parse(savedLevelsString) : {};
      this.setLogLevelsFromSavedPreferences(savedLevels);
    }
  }

  private setLogLevelsFromSavedPreferences(savedLevels: LoggingPreferences | null) {
    if (savedLevels) {
      Object.entries(savedLevels).forEach(([componentName, level]) => {
        if (Object.values(LogLevel).includes(level)) {
          this.componentLogLevels.set(componentName, level);
        }
      });
    }
  }

  log(level: LogLevel, componentName: string, message: string, ...optionalParams: any[]) {
    const effectiveLogLevel = this.getEffectiveLogLevel(componentName);
    if (this.shouldLog(level, effectiveLogLevel)) {
      switch (level) {
        case LogLevel.DEBUG:
          this.logWithStyle('color: blue;', level, componentName, message, optionalParams);
          break;
        case LogLevel.WARN:
          this.logWithStyle('color: orange;', level, componentName, message, optionalParams);
          break;
        case LogLevel.ERROR:
          this.logWithStyle('color: red;', level, componentName, message, optionalParams);
          break;
        default:
          this.logWithStyle('', level, componentName, message, optionalParams);
      }
    }
  }

  private logWithStyle(style: string, level: LogLevel, componentName: string, message: string, optionalParams: any[]) {
    const styledMessage = `%c[${level}] ${componentName}: ${message}`;
    (window as any).__originalConsoleLog(styledMessage, style, ...optionalParams);
  }

  private getEffectiveLogLevel(componentName: string): LogLevel {
    if (!this.componentLogLevels.has(componentName)) {
      this.componentLogLevels.set(componentName, this.logLevel);
      this.saveComponentLogLevels();
    }
    return this.componentLogLevels.get(componentName) || this.logLevel;
  }

  private shouldLog(messageLevel: LogLevel, effectiveLogLevel: LogLevel): boolean {
    const levels = Object.values(LogLevel);
    return levels.indexOf(messageLevel) >= levels.indexOf(effectiveLogLevel);
  }

  private async saveComponentLogLevels() {
    const levels: LoggingPreferences = {};
    this.componentLogLevels.forEach((value, key) => levels[key] = value);
    
    try {
      await this.indexedDbService.set('loggingPreferences', levels);
    } catch (error) {
      localStorage.setItem('loggingPreferences', JSON.stringify(levels));
    }
  }

  getLoggingPreferences(): { componentName: string, logLevel: LogLevel }[] {
    const preferences: { componentName: string, logLevel: LogLevel }[] = [];

    this.componentLogLevels.forEach((logLevel, componentName) => {
      preferences.push({ componentName, logLevel });
    });

    return preferences;
  }

  async updateLoggingPreferenceForComponent(componentName: string, newLogLevel: LogLevel) {
    this.componentLogLevels.set(componentName, newLogLevel);
    await this.saveComponentLogLevels();
  }
}
