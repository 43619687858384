import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorShowDialogComponent } from '@app/modules/shared/components/error-show-dialog/error-show-dialog.component';
import { BehaviorSubject, Observable, windowTime } from 'rxjs';
import { Base64 } from 'js-base64';

const mapping: any = {
  0: "Ocean",
  1: "Star",
  2: "Tree",
  3: "Cloud",
  4: "River",
  5: "Mountain",
  6: "Sun",
  7: "Sky",
  8: "Sand",
  9: "Moon",
};


@Injectable({
  providedIn: 'root'
})
export class HelperService {
  private traceQrData: any;
  
  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private http: HttpClient
  ) { }


  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  };

  showHttpErrorMsg(error: HttpErrorResponse, duration = -1) {
    console.log('httpError: ', error);

    let errorMessage = 'Some Unknown Error Occurred.';
    let errorCode = 'Unknown Error';
    const httpError = error;

    if (httpError.status >= 400) {
      const errorFromServer = httpError.error;
      if (Array.isArray(errorFromServer) && errorFromServer.length > 0) {
        errorMessage = errorFromServer[0].message;
        errorCode = errorFromServer[0].code;
      }
    } else if (httpError.status < 100) {
      errorMessage = httpError.message;
      errorCode = httpError.statusText;
    }

    if (errorCode == "VALIDATION_ERROR" || errorCode == "NOT_FOUND_ERROR") {
      let confirmBoxData: any = {
        title: "Error !",
        message: errorMessage,
        icon: "error",
        iconClass: "app-text-danger",
        buttonText: "Ok",
        buttonClass: "app-btn-primary",
      };

      const dialogRef = this.dialog.open(ErrorShowDialogComponent, {
        width: "30%",
        panelClass: ['app-simple-confirmation'],
        data: confirmBoxData,
        disableClose: false,
      });

    } else {
      this.snackBar.open(errorMessage, 'Error', { duration: -1 });
    }

  }

  showSuccessMsg(message: string, title: string, duration = 3000) {
    this.snackBar.open(message, title, {
      duration: duration
    })
  }

  showErrorMsg(message: string, title: string, duration = 3000) {
    this.snackBar.open(message, title, {
      duration: duration
    })
  }

  /**
   * 
   * @param date 
   * @returns if date passed then date as dd/mm/yyyy otherwise system date as dd/mm/yyyy
   */
  getDateDDMMYYYY(date?: Date): string {
    let dateTime: Date;

    if (date) {
      dateTime = new Date(+date);
    }
    else {
      dateTime = new Date();
    }

    const dateStr = String(dateTime.getDate()).padStart(2, '0') + "/" + String(dateTime.getMonth() + 1).padStart(2, '0') + "/" + dateTime.getFullYear();

    return dateStr;
  }

  addDaysToDate(date: Date, days: number): Date {
    return new Date(date.getTime() + (days * 24 * 60 * 60 * 1000));
  }

  differenceBetweenDatesInDays(date1: Date, date2: Date): number {
    var diff = Math.abs(date1.getTime() - date2.getTime());
    return Math.ceil(diff / (24 * 60 * 60 * 1000));
  }

  async compressFile(image: any, mimeType: string): Promise<Blob> {

    let imageData = image;

    let imageBlob: Blob = this.dataURItoBlob(imageData.split(',')[1], mimeType);
    return imageBlob;
  }

  getDataUrlFromFile(file: any) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (e: any) => {
        resolve(e.target.result)
      };
      reader.onerror = (e: any) => {
        reject(e.target.error);
      };
      reader.readAsDataURL(file);
    })
  }

  dataURItoBlob(dataURI: string, mimeType: string): Blob {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: mimeType });
    return blob;
  }

  /**
   * 
   * @param imgUrl
   * @returns if imgUrl passed then image is returned data URL as base64 string otherwise Cantrak logo is returned data URL as base64 string
   */
  localImageToBase64(imgUrl = '') {
    const url = imgUrl != '' ? imgUrl : '../../../../assets/logo/cantrack-logo-with-border.png';

    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.send();
    })

  }

  convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(";base64,")
    // hold the content type
    const imageType = parts[0].split(":")[1]
    // decode base64 string
    const decodedData = window.atob(parts[1])
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length)
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType })
  }

  uploadImageByEntity(data: any): Observable<any> {
    return this.http.post(
      "/image/upload-image-by-entity",
      JSON.stringify(data)
    );
  }

  returnByAttr(arr: any, attr: any, value: any) {
    return arr.find((item: any) => {
      return item[attr] == value;
    });
  }

  generateUniqueId(len = 7) {
    return Math.random()
      .toString(35)
      .substr(2, len);
  }

  mergeArray(data: any, keyForCompare: string, mergeKey: string, mergeType: string) {
    let items: any = [];
    data.map((item: any, index: number) => {
      data.forEach((item2: any, index2: number) => {
        if (index != index2) {
          if (item[keyForCompare] === item2[keyForCompare]) {
            switch (mergeType) {
              case 'number':
                item[mergeKey] = item.noOfDays + item2.noOfDays;
                break;

              case 'array':
                item[mergeKey] = item.noOfDays + item2.noOfDays;
                break;

              default:
                item[mergeKey] = item.noOfDays + item2.noOfDays;
            }

            if (item?.endDate) {
              item.endDate = item2.endDate;
            }
          }
        }
      });

      if (!items.filter((r: any) => r[keyForCompare] == item[keyForCompare])?.length) {
        items.push(item);
      }
    });

    return items?.length > 0 ? items : null;
  }

  uploadImageForPlants(data: any): Observable<any> {
    return this.http.post('/image/upload-image-tagg-by-entity', JSON.stringify(data));
  }

  encode(value: string | number): string {
    if(typeof value === 'number'){
      return this.encodeText(this.encodeNumber(value));
    }
    else if(typeof value === 'string'){
      return this.encodeText(value);
    }
    
    return value;
  }

  decode(value: string, type = 1): string | number{
    if(type == 1){
      return this.decodeNumber(this.decodeText(value));
    }
    else if(type == 1){
      return this.decodeText(value);
    }

    return value;
  }

  encodeText(text: string): string {
    // Convert text to byte array (UTF-8)
    const bytes = Uint8Array.from(text, (c) => c.charCodeAt(0));
    // Encode byte array to Base64
    const encodedText = btoa(String.fromCharCode(...bytes));
    return Base64.encodeURI(encodedText);
  }
  
  decodeText(encodedText: string): string {
    // Decode Base64 string to byte array
    encodedText = Base64.decode(encodedText);
    const decodedBytes = atob(encodedText);
    // Convert byte array back to text (UTF-8)
    let decodedString = '';
    for (let i = 0; i < decodedBytes.length; i++) {
      decodedString += String.fromCharCode(decodedBytes.charCodeAt(i));
    }
    return decodedString;
  }

  encodeNumber(number: number): string {
    // Convert the number to a string
    const numberString = number.toString();
    // Encode each digit using the mapping
    let encodedText = "";
    for (let i = 0; i < numberString.length; i++) {
      const digit: any = numberString.charAt(i);
      encodedText += mapping[digit] + "--"; // Add space as separator
    }
  
    // Remove trailing space
    return encodedText.trim();
  }
  
  decodeNumber(encodedText: string): number {
    // Split the encoded text by spaces
    let encodedWords = encodedText.split("--"); 
    encodedWords = encodedWords?.filter(e => e != '');
  
    // Decode each word using the mapping (reverse lookup)
    let decodedNumber = "";
    for (const word of encodedWords) {
      if(word != ''){
        const value = Object.keys(mapping).find((key: any) => mapping[key] == word);
        if (value !== undefined) {
          decodedNumber += value;
        } else {
          // Handle invalid characters (optional)
          console.warn("Warning: Invalid word found in encoded text:", word);
        }
      }
    }
  
    return parseInt(decodedNumber);
  }

  sortArrayByOrder(A: string[], B: string[]): string[] {
    const sortedA = [];
    for (const element of B) {
      const index = A.indexOf(element);
      if (index !== -1) {
        sortedA.push(A[index]);
        A.splice(index, 1);
      }
    }
    return [...sortedA, ...A]; // Combine sorted elements with remaining elements
  }

  roundNumber(number: number, decimals = 0) {
    const multiplier = Math.pow(10, decimals);
    return Math.round(number * multiplier) / multiplier;
  }

  arrangeDataByKey(data: any[], key: string, appendKey: string[] | null = null): any {
    const resultMap: any[] = [];

    data.forEach(item => {
      const existingEntry = resultMap.find((entry: any) => entry[key] === item[key]);
      if (existingEntry) {
        existingEntry.data.push(item);
      } else {
        let res: any = {};

        if(appendKey && appendKey?.length){
          appendKey?.forEach(e =>{
            res[e] = item[e];
          });
        }
        res[key] = item[key];

        resultMap.push({
          ...res,
          data: [item]
        });
      }
    });

    return resultMap;
  }

  removeDuplicates(arr: any[]) {
    return [...new Set(arr)];
  }

  setTraceQrData(data: any){
    this.traceQrData = data;
  }

  getTraceQrData(){
    return this.traceQrData;
  }

}
