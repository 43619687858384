export const environment = {
    logLevel: 'warn',
    production: true,
    stage: false,
    appVersion: '1.0.0',
    origin: "https://app.backend.farheenacademy.com/prod",
    appUrl: "https://app.farheenacademy.com",
    socketOrigin: "wss://16z5ox70r8aaaa.execute-api.ap-southeast-1.amazonaws.com/production",
    bucketUrl: "https://online-education-resources.s3.ap-southeast-1.amazonaws.com",
    cdnUrl: "https://resources.cantrak.tech",
    vapidKey: "BP1mVU-oBoplZiZzNkUzA5LcPP8ujiWbS0yZgm2QbBBhew9F59R21MXZb7i002JfihBz7jc8I5ITsQYVZ-EVvk0",
    googleClientId: "423057939767-4smipfn7q9qfmvn8p9ku121ch4kmmned.apps.googleusercontent.com"
};
