// import { CommonService } from '@app/shared/services/common.service';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import * as fromAuth from '@core/store/reducers/auth.reducers';
import * as fromApp from '@core/store/app.state';
import { Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';

export const SkipAuthInterceptorHeader = 'X-Skip-Auth-Interceptor';

/** Pass the request after adding authorization header. */
@Injectable()
export class AuthorizeRequestInterceptor implements HttpInterceptor {
  constructor(private store: Store<fromApp.AppState>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select('auth').pipe(
      take(1),
      switchMap((authState: fromAuth.State) => {

        // Skip Adding Auth Headers When there is skip header
        //console.log('Inside auth interceptor 1',req.headers)
        if (req.headers.has(SkipAuthInterceptorHeader)) {
          //console.log('Inside auth interceptor 1',req.headers)
          const headers = req.headers.delete(SkipAuthInterceptorHeader);
          //console.log('Inside auth interceptor 2',req.headers)
          return next.handle(req.clone({ headers }));
        }
        if (authState.user && authState.accessToken) {
          const secureReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + authState.accessToken)
          });
          return next.handle(secureReq);
        } else {
          return next.handle(req);
        }
      })
    );
  }
}
